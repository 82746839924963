<template>
  <v-app>
    <v-main class="background">
      <v-btn icon text x-large
      style="z-index:10000;padding:30px;position:fixed;bottom:20px;right:20px;background:#33b328;color:white;">
      <i class="fas fa-comment-dots"></i></v-btn>
      <router-view/>
    </v-main>
  </v-app>
</template>

<style>
.background {
  color: #33b328;
  background-image: url('https://grupoficenca.com/img/background.png');
  background-repeat: no-repeat;
}
</style>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
